.adminBody {
  background-color: lightgray;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hover-link:hover {
  cursor: pointer;
}