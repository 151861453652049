body {
  margin: 0;
  padding: 0;
  background-color: #2c475c
}

.top-row-container {
  height: 100%;
}

.bottom-row-container  {
  height: 100%;
}

/* Main headings (like "Team target van November") */
.heading-primary {
  text-align: center;
  font-size:clamp(1.25rem, 1.5vw, 6.0rem);   
  font-weight: bold !important;
  line-height: 1.2;
}

/* Secondary headings (like "Target: € 3500") */
.heading-secondary {
  text-align: center;
  font-size:clamp(1rem, 1.25vw, 6.0rem);   
  font-weight: 500;
  line-height: 1.2;
}

/* .SpeedoMeterBox {
  height: 55% !important;
} */

svg.speedometer {
  z-index: 300;
  display: block !important;
  margin: auto !important;
  position: relative !important;
  max-height: calc(min(25vh, 400px)) !important;
}

.h-auto.w-100 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  height: 100%;
}

.navbar {

}

/* Speedometer values (Bedrag/Te gaan) */
.value-display {
  text-align: center;
  font-weight: 600;
  color: #1a56db;
  line-height: 1.2;
  margin: 0;
}


.bodyDashboard {
  width: 100%;
  max-height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: #2c475c;
  position: relative;
}

.box-container {
  padding: 0;
  margin: 0;
  height: 90dvh;
}

.top-row-container {
  display: flex;
  flex-direction: row;
}

 @media (max-width: 992px) {
  .box-container {
    height: 100% !important;
  }
}

@media (max-width: 768px) {

  .custom-padding-end:first-child {
    height: 127px !important;
  }
}

@media (min-width: 992px) {
  .custom-padding-start {
    padding-left: 6px !important;
    padding-right: 0 !important;
  }
  .custom-padding-end {
    padding-right: 6px !important;
    padding-left: 0 !important;
  }
  
  /* First box */
  .custom-padding-end.pb-lg-4 {
    padding-right: 12px !important;
    padding-bottom: 12px !important;
  }
  
  /* Second box */
  .custom-padding-start.pb-lg-4 {
    padding-left: 12px !important;
    padding-bottom: 12px !important;
  }
  
  /* Third box */
  .custom-padding-end:not(.pb-lg-4) {
    padding-right: 12px !important;
    padding-top: 12px !important;
  }
  
  /* Fourth box */
  .custom-padding-start:not(.pb-lg-4) {
    padding-left: 12px !important;
    padding-top: 12px !important;
  }
}


.current {
  color: rgb(103 148 220);
}

.toGo {
  color: rgb(220 103 206);
}

.totalmoney {
  color: rgb(103 148 220);
}

.dealsAndGemOrders {
  color: rgb(128 103 220);
}

.sellerofthemonth {
  color: rgb(163 103 220);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.myButton {
  opacity: 0;
}

.myButton:hover {
  cursor: pointer;
}


.midbox {
  background-color: #ffffff;
    /* max-height: clamp(405px, 1vw, 418px) !important; */
  /*height: 300px;
  width: 45.5%;*/
}

.bigbox {
  background-color: #ffffff;
}

.biggestbox {
  background-color: #ffffff;
  height: 280px;
}

.smallboxes {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.box-heading {
  font-size:clamp(1em, 1vw, 5.0rem);   
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.box-value {
  font-size: clamp(1rem, 1.75vw, 7rem);
  color: #1a56db;
}

.color-blue {
  color: #1a56db;
}

.smallboxes-display-header {
  font-size: clamp(1rem, 2.5vw, 3.25rem);
}

.smallboxes-display-value {
  font-size: clamp(0.8rem, 2.5vw, 2.75rem);
}

.value-small {
  font-size:clamp(1.25rem, 1.1vw, 5.0rem);   
  font-weight: 500;
  color: rgb(128 103 220);
}

.label-small {
  font-size: clamp(1rem, 1.1vw, 5rem);
  font-weight: 600;
}

.sellerofthemonth {
  color: rgb(163 103 220);
  font-size: clamp(1.5rem, 1.75vw, 6rem);
}

.icon {
  transform: rotate(20deg);
}

.totalmoney {
  color: rgb(103 148 220);
  font-weight: bolder
}

.current {
  color: rgb(103 148 220);
  font-weight: bold;
}

.biggestBoxChart {
  position: relative;
  bottom: 70px;
}

.toGo {
  color: rgb(220 103 206);
  font-weight: bold;
}

.dealsAndGemOrders {
  color: rgb(128 103 220);
  font-weight: bolder
}

.sellerofthemonth {
  color: rgb(163 103 220);
}

.textEndGoal {
  position: relative;
  font-size: small;
  bottom: 50px;
  font-size: clamp(1rem, 1.5vw, 2.5rem);

}

.text {
  font-size: medium;
}

.custom-spinner img {
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Chart Container Styles */
.yearly-chart-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .yearly-chart-container {
    height: auto !important;
  }
}

.chart-header {
  padding: clamp(1rem, 2vh, 2rem);
}

.chart-wrapper {
  flex: 1;
  max-height: 39dvh !important; /* anders 100% */
  width: 100%;
  padding-left: clamp(0.5rem, 1vw, 1.5rem);
  padding-right: clamp(0.5rem, 1vw, 1.5rem);
}

.xaxislayer-above {
  padding-top: 500px !important;
}

/* .xaxislayer-above .xtick {
  white-space: none !important;
} */

/* .xaxislayer-above .xtick text {
  white-space: none !important;
  transform: translate(0, 5px);
} */


/* .xaxislayer-above .xtick text .line{

  background-color: pink;
} */

@media screen and (min-width: 1900px) {
  .xaxislayer-above .xtick text {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 1400px) {
  .xaxislayer-above .xtick text {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1900px) {
  .xaxislayer-above .xtick text {
    font-size: 16px !important;
    x: 10;
  }
}

.plot-container {
  max-height: 300px;
}

.xaxislayer-above .xtick text .line {

}

/* Plot Responsive Styles */
.responsive-plot {
  width: 100% !important;
  height: 100% !important;
}

/* Core text scaling - simplified to avoid conflicts */
.js-plotly-plot .plotly .ytick text,
.js-plotly-plot .plotly .xtick text {
  font-weight: bold !important;
}

/* Container queries for better responsiveness */
@container (min-width: 768px) {
  .chart-wrapper {
    padding: clamp(1rem, 2vw, 2rem);
  }
}
g[class*='xaxislayer-above.xtick'],g[class^='xaxislayer-above.xtick'] text{
  color: pink;
  fill: pink;
}

