.chart-container {
  width: 100%;
  height: clamp(70%, 30vh, 400px);
}

.chart-container svg {
  width: 100%;
  height: 100%;
}

@media screen {
  
}

.main-svg {
  height: 100% !important;
}

.svg-container {
  max-height: 100% !important;
  min-height: 230px !important;
}

.midBoxCharts {
  display: flex;
  align-items: center;
  height: 35dvh;
}

.chart-container svg g {
  margin-bottom: clamp(4px, 0.5vh, 8px);
}

/* Improve text alignment */
.chart-container svg text {
  dominant-baseline: middle;
  alignment-baseline: middle;
}

.no-padding-left-no-right {
  padding-left: 0px !important;
  padding-right: 0px !important;
}


/* Update the midbox styles */
.midbox {
  background-color: #ffffff;
  /* min-height: clamp(300px, 35vh, 450px); */
}

   .SpeedoMeterBox {
    transform: scale(var(--scale, 1));
    transform-origin: center;
  }
  
  @media (min-width: 1920px) {
    .SpeedoMeterBox {
      --scale: 1.2;
    }
  }
  
  @media (max-width: 768px) {
    .SpeedoMeterBox {
      --scale: 0.8;
    }
  }

/* 
.chartBar > div > div > div > div {
    width: 200px !important;
}

.midBoxCharts > div > div > div > svg {
    background: rgb(0, 0, 0, 0) !important;
}
.biggestBoxChart > div > div > div > svg {
    background: rgb(0, 0, 0, 0) !important;
}

.biggestbox > div > div > div > div > div > svg > g > rect {
  display: none;
}

.midbox > div > div > div > div > div > div > svg > g > g > rect {
  display: none;
}

.midbox > div > div > div > div > div > div > svg {
  position: relative;
  left: 0;  /* Remove the fixed left positioning */ /*
  overflow: visible;  /* Allow text to be fully visible */ /*
  width: 100% !important;
  height: 100% !important;
}

.midbox > div > div > div > div > div > div > svg > g > g > g > text {
  position: relative !important;  /* Change from absolute */ /*
  font-size: inherit !important;  /* Let our clamp values control size */ /*
}

.biggestbox > div > div > div > div > div > svg {
  position: relative;
  left: 12px;
}
  .biggestbox > div > div > div > div > div > svg > g > path:nth-child(1n) {
    fill: rgb(163 103 220);
  }
  .biggestbox > div > div > div > div > div > svg > g > path:nth-child(2n) {
    fill: rgb(103 183 220);
  }
  .biggestbox > div > div > div > div > div > svg > g > path:nth-child(3n) {
    fill: rgb(166 221 242);
  }
  .biggestbox > div > div > div > div > div > svg > g > path:nth-child(4n) {
    fill: rgb(199 103 220);
  }
  .biggestbox > div > div > div > div > div > svg > g > path:nth-child(5n) {
    fill: rgb(103 113 220);
  }
  .biggestbox > div > div > div > div > div > svg > g > path:nth-child(6n) {
    fill: rgb(78 126 166);
  }
  
  .midbox > div > div > div > div > div > div > svg > g > g > g > rect:first-child[width="1"] {
    display: block;
    width: 3px;
    border-radius: 100%;
  }

  .midbox > div > div > div > div > div > div > svg > g > g > g > rect:nth-child(1n)[height="24"] {
    fill: rgb(163 103 220); 
  }
  .midbox > div > div > div > div > div > div > svg > g > g > g > rect:nth-child(2n)[height="24"] {
    fill: rgb(103 183 220); 
  }
  .midbox > div > div > div > div > div > div > svg > g > g > g > rect:nth-child(3n)[height="24"] {
    fill: rgb(166 221 242); 
  }
  .midbox > div > div > div > div > div > div > svg > g > g > g > rect:nth-child(4n)[height="24"] {
    fill: rgb(199 103 220); 
  }
  
  .midbox > div > div > div > div > div > div > svg > g > g > g > rect[width="1"] {
    display: none; 
  }
  
  .midbox > div > div > div > div > div > div > svg > g > g > g >text[text-anchor="middle"] {
    display: none; 
  }
*/