.body {
    height:  89dvh;
    background-color: #dee0e2;
}

#password {
    width: 500px;
    height: 50px;
}

#logoLogin {
    height: 11dvh;
    width: fit-content;
}